html,
body {
  font-size: 20px;
  color: #444;
}

html,
body,
#root {
  height: 100%;
  background: #eee;
}

* {
  box-sizing: border-box;
}

/* Safe spaces for devices with Notches. Value is 0px when it is not needed */
.safe-area-pt {
  padding-top: env(safe-area-inset-top);
  /* padding-top: 50px; */
}
.safe-area-pr {
  padding-right: env(safe-area-inset-right);
  /* padding-right: 50px; */
}
.safe-area-pb {
  padding-bottom: env(safe-area-inset-bottom);
  /* padding-bottom: 50px; */
}
.safe-area-pl {
  padding-left: env(safe-area-inset-left);
  /* padding-left: 50px; */
}
.safe-area-mt {
  margin-top: env(safe-area-inset-top);
  /* margin-top: 50px; */
}
.safe-area-mr {
  margin-right: env(safe-area-inset-right);
  /* margin-right: 50px; */
}
.safe-area-mb {
  margin-bottom: env(safe-area-inset-bottom);
  /* margin-bottom: 50px; */
}
.safe-area-ml {
  margin-left: env(safe-area-inset-left);
  /* margin-left: 50px; */
}
