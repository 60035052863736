.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: all 300ms;
  transition-delay: 100ms;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: all 300ms;
  transition-delay: 100ms;
}
